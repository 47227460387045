
    .talron-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: white;
    padding: 10px 20px;
    z-index: 3;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.2);

    .talron-menu-bars {
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 20px; // old iphone support
        inset-inline-start: 20px;
        width: 17px;
        height: 12px;
        margin-top: -6px;
        line-height:0;
        cursor: pointer;
    }

    svg {
        width: 17px;
        height: 12px;
    }

    .logo {
        height: 40px;
        width: auto;
    }
}

    .main-nav {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        inset-inline-start: 0;
        z-index: 50;



        .nav-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1;
            background: rgba(0,0,0,0.8);
        }


        .main-nav-inner {
            position: relative;
            height: 100vh;
            width: clamp(300px, 50vw, 500px);
            top: 0;
            inset-inline-start: 0;
            z-index: 2;
            border-radius: var(--border-radius-medium) 0 0 0 ;
            background: var(--global-background);
            padding: var(--global-margin) 0;
        }

        .menu-close {
            position: absolute;
            top: var(--global-margin);
            inset-inline-start: var(--global-margin);
            z-index: 2;
            cursor: pointer;
        }

        .menu-logo {

            .menu-logo-image {
                width: 80px;
                height: auto;
            }
        }

        .nav {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        li {
            display: block;

            &:first-of-type {
                a {
                    border-top: 1px solid  #ddd;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: var(--global-margin);
            text-decoration: none;
            color: var(--global-color);
            border-bottom: 1px solid  #ddd;
            padding: var(--global-margin);
            font-size: var(--font-m);
            font-weight: 600;

            &.uk-active {
                background: var(--global-muted-background);
            }

            .icon-end {
                margin-inline-start: auto;
                margin-inline-end: 0;
            }


        }
    }

    ul a {

    }

